<!--
 * @Description: 内容发布--资讯帖子--资讯列表页面
 * @Author: 小广
 * @Date: 2019-06-17 18:16:21
 * @LastEditors: 小广
 * @LastEditTime: 2019-10-12 11:20:21
 -->
<template>
  <div class="informationList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="标题" v-model="searchParams.title"></v-input>
        <v-select label="所属App" v-model="searchParams.appType" :options="appTypeOps" @change="appTypeChange"></v-select>
        <v-select label="类别" v-model="searchParams.type" :disabled="searchParams.appType === undefined" :options="typeOps" @change="typeChange"></v-select>
        <v-select label="子类别" v-model="searchParams.sectype" :disabled="!searchParams.type || searchParams.type.length === 0" :options="sectypeOps"></v-select>
        <v-select label="跳转类型" v-model="searchParams.skipType" :options="skipTypeOps"></v-select>
        <v-select2 label="发布项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-select label="状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker
          type="rangedatetimer"
          label="创建时间段"
          :maxDate="maxDate"
          :startTime.sync="searchParams.regFromDate"
          :endTime.sync="searchParams.regToDate">
        </v-datepicker>
      </template>
      <template #operateSlot="scope">
        <div >
          <v-button v-if="scope.row.status === '1'" text="编辑" type="text" permission="update" @click="edit(scope.row)"></v-button>
          <v-button text="复制" type="text" permission="add" @click="copy(scope.row)"></v-button>
          <v-button v-if="scope.row.status === '1'" text="关闭" type="text" permission="delete" @click="offHandle(scope.row)"></v-button>
        </div>
      </template>
      <template #batchSlot="scope">
        <v-button text="批量关闭" permission="delete" @click="batchAuditOffHandle(scope.selectedData)"></v-button>
      </template>
      <el-dialog
        :title="dialogTitle"
        width="500px"
        :visible.sync="dialogVisible"
      >
        <div class="dialog-content" >
          <div class="dialog-h5" v-html="dialogContent"></div>
        </div>
      </el-dialog>
    </list>
  </div>
</template>

<script>
import { getInfomationListURL, batchInfomationURL, offInfomationURL, getOrgTagListURL, getRuleCodeLevelListURL } from './api'
import { statusMap, statusOps, skipTypeOps, skipTypeMap, appTypeMap, appTypeOps } from './map'
import { communityParams } from 'common/select2Params'
import { mapHelper } from 'common/utils'
import { createAlinkVNode } from 'common/vdom'
import moment from 'moment'

export default {
  name: 'InformationPostList',
  data () {
    return {
      maxDate: moment().format('YYYY-MM-DD HH:mm'),
      searchUrl: getInfomationListURL,
      statusOps: statusOps(1),
      typeOps: [],
      sectypeOps: [],
      skipTypeOps: skipTypeOps(1),
      appTypeOps: appTypeOps(1),
      communityParams: communityParams,
      orgTagParams: {
        searchUrl: getOrgTagListURL,
        request: {
          text: 'tagName',
          value: 'tagId'
        },
        response: {
          text: 'tagName'
        }
      },
      searchParams: {
        title: '',
        type: undefined,
        sectype: undefined,
        skipType: undefined,
        communityId: '',
        status: undefined,
        appType: undefined,
        regFromDate: '',
        regToDate: ''
      },
      headers: [
        {
          prop: 'titleVNode',
          label: '标题',
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.title,
              cb: this.showInfoDetail
            })
          }
        },
        {
          prop: 'appTypeStr',
          label: '所属App',
          formatter (row) {
            return appTypeMap[row.appType]
          }
        },
        {
          prop: 'typeText',
          label: '类别',
          formatter (row) {
            return `${row.type} ${row.sectype}`
          }
        },
        {
          prop: 'skipTypeStr',
          label: '跳转类型',
          formatter (row) {
            return skipTypeMap[row.skipType]
          }
        },
        {
          prop: 'keywords',
          label: '关键字'
        },
        {
          prop: 'date',
          label: '创建时间'
        },
        {
          prop: 'communityName',
          label: '发布项目'
        },
        {
          prop: 'orgTagNames',
          label: '关联组织标签'
        },
        {
          prop: 'viewCount',
          label: '阅读数',
          align: 'right'
        },
        {
          prop: 'sort',
          label: '排序',
          align: 'right'
        },
        {
          prop: 'orgName',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        }
      ],
      dialogVisible: false,
      dialogTitle: '',
      dialogContent: ''
    }
  },

  created () {

  },

  mounted () {
    // 获取类别
    if (this.searchParams.appType !== undefined) {
      this.getRuleCodeLevelList(1)
      if (this.searchParams.type !== undefined) {
        this.getRuleCodeLevelList(2)
      }
    }
  },

  methods: {
    create () {
      this.$router.push({
        name: 'informationForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'informationForm',
        query: {
          id: row.id
        }
      })
    },

    copy (row) {
      this.$router.push({
        name: 'informationForm',
        query: {
          id: row.id,
          copy: '1'
        }
      })
    },

    // 类别变化
    typeChange (value) {
      this.$set(this.searchParams, 'sectype', undefined)
      // 获取子类别
      this.getRuleCodeLevelList(2)
    },

    appTypeChange (val) {
      // 所属APP改变
      this.$set(this.searchParams, 'type', undefined)
      if (val !== undefined) {
        this.getRuleCodeLevelList(1)
      } else {
        this.$set(this.searchParams, 'sectype', undefined)
      }
    },

    // 点击标题显示跳转内容弹框
    showInfoDetail (row) {
      this.dialogVisible = true
      this.dialogTitle = row.title
      this.dialogContent = `<div>${row.content}</div>`
    },

    // 关闭的操作
    async offHandle (row) {
      let isOk = await this.$confirm('确认关闭？', { title: '提示' })
      isOk && this.requestForOff(row)
    },

    // 批量审核/关闭
    async batchAuditOffHandle (selectedData) {
      let list = selectedData.data || []
      if (list.length) {
        let firstStatus = list[0].status
        // 取出所选list，判断里面的数据状态是否一致
        let allSame = true
        list.forEach(item => {
          if (firstStatus !== item.status) {
            allSame = false
          }
        })

        if (allSame) {
          /// 代表所选数据状态一致，进一步判断
          if (firstStatus !== '1') {
            this.$message('请确认所选资讯在可关闭状态')
          } else {
            // 关闭的处理
            let isOk = await this.$confirm('确定执行该操作？', { title: '提示' })
            isOk && this.requestForAuditOff(selectedData)
          }
        } else {
          this.$message('所选的资讯状态不一致，请重新操作')
        }
      } else {
        this.$message('请先选择要操作的数据')
      }
    },

    // 关闭的请求
    requestForOff (row) {
      let _this = this
      this.$axios({
        method: 'POST',
        url: offInfomationURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: {
          id: row.id,
          dataObject: row.title
        }
      }).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message.success('操作成功')
        }
      })
    },

    // 批量审核/关闭的请求
    requestForAuditOff (selectedData) {
      let _this = this
      this.$axios({
        method: 'POST',
        url: batchInfomationURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        data: {
          ids: selectedData.ids.join(',')
        }
      }).then(res => {
        if (res.status === '100') {
          _this.$refs.list.searchData()
          _this.$message.success('操作成功')
        }
      })
    },

    // 获取类别
    async getRuleCodeLevelList (level) {
      let postData = { codeLevel: level, appType: this.searchParams.appType }
      if (level === 2 && this.searchParams.type && this.searchParams.type.length) {
        // 子类需要传值superId
        postData['superid'] = this.searchParams.type
      }
      let res = await this.$axios.get(getRuleCodeLevelListURL, { params: postData })
      const { data, status } = res
      let typeList = []
      if (status === 100) {
        let list = data || []
        typeList = list.map(item => {
          return {
            text: item.value,
            value: item.id
          }
        }) || []
      }
      const { setOps: typeOps } = mapHelper.setMap(typeList)
      if (level === 1) {
        this.typeOps = typeOps(1)
      } else {
        this.sectypeOps = typeOps(1)
      }
    }
  }
}
</script>

<style scoped>
.dialog-content {
  height: 400px;
  margin-top: -20px;
  overflow-y:auto;
  overflow-x:hidden;
  width: 100%;
}

.dialog-h5 >>> img,p,span {
  width: 100%;
}

</style>
